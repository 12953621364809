import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/default_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Information`}</h1>
    <p>{`In this reading group we will be make use the following two books:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.cambridge.org/core/books/levy-processes-and-stochastic-calculus/4AC698D37D3D8E57D099B73ADF4ACB11"
        }}>{`"Lévy Processes and Stochastic Calculus"`}</a>{` (Second Edition) by David Applebaum`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.springer.com/gp/book/9783642376313"
        }}>{`"Fluctuations of Lévy Processes with Applications"`}</a>{` by Andreas E. Kyprianou`}</li>
    </ul>
    <p>{`The `}<a parentName="p" {...{
        "href": "#schedule"
      }}>{`schedule`}</a>{` lists the material that participants are suggested to read before each session. Members of the reading group are encouraged to participate in presenting the material.`}</p>
    <p>{`We are using Teams to conduct the session. Here is the `}<a parentName="p" {...{
        "href": "https://teams.microsoft.com/l/meetup-join/19%3ae40320edb7ad4faf846534c1404a1866%40thread.tacv2/1619617596594?context=%7b%22Tid%22%3a%22cc95de1b-97f5-4f93-b4ba-fe68b852cf91%22%2c%22Oid%22%3a%22ab3f21bb-25c3-4790-a107-7ef01c85e63f%22%7d"
      }}>{`link`}</a>{` to the meeting. Please email me or Terence if you've signed up and haven't been added to the team.`}</p>
    <h1>{`Prequisites`}</h1>
    <p>{`A basic knowledge of measure and probability theory is needed. The material in Section 1.1 of Lévy Processes and Stochastic Calculus serves as a good review of these topics. Familiarity with Brownian motion is helpful but not essential.`}</p>
    <h1>{`Accessing the reading material`}</h1>
    <p>{`"Lévy Processes and Stochastic Calculus" by David Applebaum can be accessed online in a PDF format if you have an Oxford Single Sign-On. Please follow these steps:`}</p>
    <ol>
      <li parentName="ol">{`Press the "Log in" button on the top right of `}<a parentName="li" {...{
          "href": "https://www.cambridge.org/core/books/levy-processes-and-stochastic-calculus/4AC698D37D3D8E57D099B73ADF4ACB11"
        }}>{`this page`}</a></li>
      <li parentName="ol">{`Choose "Log in via Shibboleth or Athens"`}</li>
      <li parentName="ol">{`Select "University of Oxford" as the organization`}</li>
      <li parentName="ol">{`Sign in using your Oxford Single Sign-On details`}</li>
    </ol>
    <p>{`Below each chapter there should now be a green checkmark with "Access" written next to it. Click on PDF to access the file for each chapter.`}</p>
    <p>{`"Fluctuations of Lévy Processes with Applications" by Andreas E. Kyprianou can be accessed `}<a parentName="p" {...{
        "href": "https://ezproxy-prd.bodleian.ox.ac.uk:2120/book/10.1007%2F978-3-642-37632-0"
      }}>{`here`}</a>{` using an Oxford Single Sign-On.`}</p>
    <h1>{`Sign up`}</h1>
    <p>{`Please sign up using the form `}<a parentName="p" {...{
        "href": "https://forms.gle/NuX9gMVFpdGs37Qd8"
      }}>{`here`}</a>{`. Alternatively you can email an organiser using the contact details below.`}</p>
    <h1>{`Contact us`}</h1>
    <p>{`This reading group is co-organised by `}<a parentName="p" {...{
        "href": "https://holungrandomcorner.wordpress.com/about-me/"
      }}>{`Terence Tsui`}</a>{` and Zheneng Xie of the Oxford University Statistics Department. Please contact us at `}<a parentName="p" {...{
        "href": "mailto:xie@maths.ox.ac.uk"
      }}>{`xie@maths.ox.ac.uk`}</a>{` or `}<a parentName="p" {...{
        "href": "mailto:terence.tsui@maths.ox.ac.uk"
      }}>{`terence.tsui@maths.ox.ac.uk`}</a>{` if there are any questions. We also welcome suggestions or other feedback. Feedback can also be submitted anonymously `}<a parentName="p" {...{
        "href": "https://forms.gle/V9DUeZjkjURrSXBd7"
      }}>{`here`}</a>{`.`}</p>
    <h1>{`Schedule`}</h1>
    <p><strong parentName="p">{`Sessions are on Friday from 11:00 to 12:00 BST starting Week 2 on Jan 25`}</strong></p>
    <p>{`The following information can also be found in this `}<a parentName="p" {...{
        "href": "https://calendar.google.com/calendar/u/0?cid=MWg4MzAzc21hYWhjdnFqYmpocnUxYjBxc3NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ"
      }}>{`Google Calendar`}</a>{`. Note this schedule is subject to change depending on progress in the actual sessions.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Week`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Reading Material`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Topic`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`2`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`07/05`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`"Lévy Processes and Stochastic Calculus" Pages 99--112`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`Jumps of a Lévy process: Poisson random measures`}</del></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`3`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`14/05`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`"Lévy Processes and Stochastic Calculus" Pages 113--131`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`Lévy--Itô decomposition`}</del></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`4`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`21/05`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`"Lévy Processes and Stochastic Calculus" Pages 113--131`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`Lévy--Itô decomposition`}</del></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`5`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`28/05`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`"Fluctations of Lévy Processes with Applications" Chapter 6`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`Wiener--Hopf factorisation`}</del></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`6`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`04/06`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`"Fluctations of Lévy Processes with Applications" Chapter 6`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`Wiener--Hopf factorisation`}</del></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`7`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`11/06`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`Lévy trees`}</del></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`8`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`18/06`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`Lévy trees`}</del></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      